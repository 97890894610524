figure {
    display: inline-block;
    /*border: 1px dotted gray;*/
    /* margin: 20px;  adjust as needed */
}

figure > img {
    vertical-align: top;
}

figure figcaption {
    padding: 5px;
    text-align: center;
}

h1,
.h1 {
  margin-top: 1rem;
}